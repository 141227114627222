<template>
    <div class="wrap">
        <div class="page-main">
            <div style="margin-top:30px;padding: 20px;">
                <iframe :src="frameSrc" :style="{height: height}" frameborder="0" scrolling="no"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            frameSrc:process.env.VUE_APP_RULES,
            height:'auto',
        };
    },

    components: {},

    computed: {},

    created() {},

    mounted() {
        window.addEventListener("message",(event)=>{
            console.log(event)
            if (event && event.data && typeof event.data === 'string') {
                let obj = JSON.parse(event.data)
                if (obj.type === 'setHeight') {
                    this.height = obj.data + 'px'
                }
            }
        }, false)
    },
};
</script>
<style lang="less" scoped>
iframe{
    width: 100%;
}
</style>
